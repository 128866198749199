export const defaultState = {
  units: [],
  unitTypes:[],
  treeUnits: {},
  selectedUnit:{},
  positions:[],
  searchUnit:null,
  searchPosition:null,
  searchCurrentPositions:[],
  salaryStructures:[],
  employeeGroups:[],
  hiringModes:[],
  echelons:[],
  levels:[],
  positionEmployees:[],
  supervisorPositions:[],
  positionQualifications:[],
  degrees:[],
  degreeQualifications:[],
  professionalCertificates:[],
  knowledges:[],
  certificateTypes:[],
  professionalCertificateTypes:[],
  positionKnowledges:[],
    ///////////////////////////////////EMPLOYEEES
    searchEmployee:null,
    searchCurrentEmployees:[],
    employees:[],
    nationalIdDetail:null
};
