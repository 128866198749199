const types = {
  SET_TREE_UNITS: "SET_TREE_UNITS",
  SET_UNITS: "SET_UNITS",
  SET_UNIT_TYPES: "SET_UNIT_TYPES",
  DELETE_UNIT: "DELETE_UNIT",
  SELECTED_UNIT: "SELECTED_UNIT",
  SET_POSITIONS:"SET_POSITIONS",
  SEARCH_UNIT:"SEARCH_UNIT",
  SEARCH_POSITION:"SEARCH_POSITION",
  SEARCH_CURRENT_POSITIONS:"SEARCH_CURRENT_POSITIONS",
  GET_SALARY_STRUCTURE:"GET_SALARY_STRUCTURE",
  GET_EMPLOYEE_GROUPS:"GET_EMPLOYEE_GROUPS",
  GET_HIRING_MODES:"GET_HIRING_MODES",
  ADD_POSITION:"ADD_POSITION",
  GET_LEVELS:"GET_LEVELS",
  GET_ECHELONS:"GET_ECHELONS",
  DELETE_POSITION:"DELETE_POSITION",
  SET_POSITION_PLANNER:"SET_POSITION_PLANNER",
  SET_POSITION_HEAD_UNITY:"SET_POSITION_HEAD_UNITY",
  GET_POSITION_EMPLOYEES:"GET_POSITION_EMPLOYEES",
  GET_SUPERVISOR_POSITIONS:"GET_SUPERVISOR_POSITIONS",
  UPDATE_POSITION:"UPDATE_POSITION",
  GET_POSITION_QUALIFICATIONS:"GET_POSITION_QUALIFICATIONS",
  GET_DEGREES:"GET_DEGREES",
  GET_DEGREE_QUALIFICATIONS:"GET_DEGREE_QUALIFICATIONS",
  UPDATE_QUALIFICATION:"UPDATE_QUALIFICATION",
  ADD_QUALIFICATION:"ADD_QUALIFICATION",
  DELETE_QUALIFICATION:"DELETE_QUALIFICATION",
  GET_PROFESSIONAL_CERTIFICATES:"GET_PROFESSIONAL_CERTIFICATES",
  GET_KNOWLEDGE:"GET_KNOWLEDGE",
  GET_CERTIFICATE_TYPES:"GET_CERTIFICATE_TYPES",
  GET_PROFESSIONAL_CERTIFICATE_TYPES:"GET_PROFESSIONAL_CERTIFICATE_TYPES",
  ADD_PROFESSIONAL_CERTIFICATE:"ADD_PROFESSIONAL_CERTIFICATE",
  DELETE_PROFESSIONAL_CERTIFICATE:"DELETE_PROFESSIONAL_CERTIFICATE",
  GET_POSITION_KNOWLEDGES:"GET_POSITION_KNOWLEDGES",
  DELETE_POSITION_KNOWLEDGE:"DELETE_POSITION_KNOWLEDGE",
  ADD_POSITION_KNOWLEDGE:"ADD_POSITION_KNOWLEDGE",
  SET_EMPLOYEES:"SET_EMPLOYEES",
  SEARCH_EMPLOYEES:"SEARCH_EMPLOYEES",
  SEARCH_CURRENT_EMPLOYEES:"SEARCH_CURRENT_EMPLOYEES",
  ADD_EMPLOYEE:"ADD_EMPLOYEE",
  UPDATE_EMPLOYEE:"UPDATE_EMPLOYEE",
  GET_NATIONAL_ID_DETAIL:"GET_NATIONAL_ID_DETAIL"
};

export default types;
