const types = {
  START_LOADING: "START_LOADING",
  END_LOADING: "END_LOADING",
  CLEAN_STATE: "CLEAN_STATE",
  SET_STRONG_PASSWORD: "SET_STRONG_PASSWORD",
  SET_USER: "SET_USER",
  LOGOUT: "LOGOUT",
};

export default types;
