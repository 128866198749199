export const defaultState = {
  loading: false,
  strongPassword: true,
  user: {},
  expectedResults: [],
  selectedExpectedResult: {},
  indicators: [],
  unitIndicators: [],
  selectedIndicator: {},
  indicatorActivities: [],
  selectedActivity: {},
  myActivities: [],
  subordinates: [],
  fiscalYears: [],
  selectedFiscalYear: {},
  userEntities: [],
  selectedUserEntity: {},
  units: [],
  institutionsEvaluations: [],
  unitsEvaluations: [],
  employeesEvaluations: [],
  subordinatesEvaluations: [],
  subordinateActivities: [],
  competenciesDescriptors: [],
  rraEvaluation: {},
  rraOverallEvaluations: [],
  myScores: {},
  subordnateScore: {},
};
